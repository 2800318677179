export const menuTitle = {
    'agent-details': 'Agent Details',
    'menu': 'Menu',
    'all-transaction': 'All Transaction',
    'buy-plan': 'Buy Plan',
    'bank-details': 'BANK DETAILS',
    'recharge-details': 'Recharge-Details',
    'salary-wallet': 'Salary Wallet',
    'agent-wallet': 'Agent Wallet',
    'security-deposit': 'Security Deposit'
}

export const plansData = [
    {
        amount: "10,000 ₹",
        link: "https://telegram.me/wwwacewinin"
    },
    {
        amount: "20,000 ₹",
        link: "https://telegram.me/wwwacewinin"
    },
    {
        amount: "50,000 ₹",
        link: "https://telegram.me/wwwacewinin"
    }
    ,
    {
        amount: "1,00,000 ₹",
        link: "https://telegram.me/wwwacewinin"
    },
    {
        amount: "Plan above 1 Lakh",
        link: "https://telegram.me/wwwacewinin"
    }

]