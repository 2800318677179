import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserTransactions } from '../../store/transactions/transactionActions';
const withTransactionInfo = (WrappedComponent) => {
    return (props) => {
        const { transactions } = useSelector((state) => state.transactions)
        const dispatch = useDispatch()
        useEffect(() =>{
           dispatch(getUserTransactions())
        },[])

        return <WrappedComponent {...props} transactions={transactions} />;
    };
};

export default withTransactionInfo