import React from "react";
import { Link } from "react-router-dom";
import { plansData } from "../../constants";

const Plans = () => {
 
  return (
    <div className="my-10">
      {(
            plansData.map((item, i) => (
              <div className="flex justify-center" key={i}>
              <a  href={item?.link} target="_blank" className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-12 rounded-xl flex justify-center">
                ₹ {item?.amount}
              </a>
            </div>
            ))
          )}
      <div className="flex justify-center">
        <Link
          to="/"
          className="w-60 bg-[#4942ce] my-24 p-2 py-3 shadow-lg rounded-full flex justify-center"
        >
          Back
        </Link>
      </div>
    </div>
  );
};

export { Plans };

