import { injectStore } from '../utils/axiosConfig';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice'
import tansactionsReducer from './transactions/transactionSlice'
import plansReducer from './plans/plansSlice'
import bankSlice from './bank/bankSlice';
import SalarySlice from './salary/salarySlice'
import RechargeSlice from './recharge/rechargeSlice'
const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    transactions: tansactionsReducer,
    plans: plansReducer,
    bank: bankSlice,
    salary: SalarySlice,
    recharge: RechargeSlice
  },
});

injectStore(store);

export default store;
