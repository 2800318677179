import React from "react";

const Button = ({ style, text, ...rest }) => {
  return (
    <button className={style + " rounded-full font-bold"} {...rest}>
      {text}
    </button>
  );
};

export { Button };
