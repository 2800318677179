import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getBanks } from '../../store/bank/bankActions'

const Bankdeatils = () => {

  const { banks } = useSelector((state) => state.bank)
  const data = banks?.data || {}
  const dispatch = useDispatch()
  useEffect(() =>{
     dispatch(getBanks())
  },[])

  return (
    <div>
    <div className="flex justify-center">
      <p className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-12 rounded-xl flex ">
        A/c No : 
        <span className='ml-4'> 
           {data?.id}
        </span>
      </p>
    </div>
    <div className="flex justify-center">
      <p className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-5 rounded-xl flex ">
      IFSC :
      <span className='ml-4'> 
            PYTM-0123-456
        </span>
      </p>
    </div>
    <div className="flex justify-center">
      <p className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-5 rounded-xl flex ">
      NAME :
      <span className='ml-4'> 
            {data?.name}
        </span>
      </p>
    </div>
    <div className="flex justify-center">
      <p  className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-5 rounded-xl flex ">
      UPI ID :
      <span className='ml-4'> 
            {data?.upiId}
        </span>
      </p>
    </div>
    <div className="flex justify-center">
      <p className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-5 rounded-xl flex">
        USDT Add :
        <span className='ml-4'> 
         ₹ {data?.todayReceived}
        </span>
        
      </p>
    </div>
    
    <div className="flex justify-center">
    <Link to="/" className="w-60 bg-[#4942ce] my-24 p-2 py-3 shadow-lg rounded-full flex justify-center">
      Home
    </Link>
  </div>
  </div>
  )
}


export { Bankdeatils }