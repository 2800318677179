import { createSlice } from "@reduxjs/toolkit";
import { getRechargeWallet } from "./rechargeActions";

const initialState = {
  loading: false,
  recharge: null,
  error: null,
  success: false,
};

const RechargeSlice = createSlice({
  name: "salry-slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRechargeWallet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRechargeWallet.fulfilled, (state, action) => {
        state.loading = false;
        state.recharge = action.payload;
      })
      .addCase(getRechargeWallet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default RechargeSlice.reducer