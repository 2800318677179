import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../store/user/userActions';

const withUserInfo = (WrappedComponent) => {
    return (props) => {
        const { user } = useSelector(state => state.user);
        const userInfo = user?.data || {};
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(getUser());
        }, []);

        return <WrappedComponent {...props} userInfo={userInfo} />;
    };
};

export default withUserInfo