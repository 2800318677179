import React, { useEffect, useState } from 'react'
import { MdOutlineCurrencyRupee } from 'react-icons/md'
import { Link } from 'react-router-dom'
import withUserInfo from '../../hoc/userInfo'
import { formatDateString } from '../../utils'

const AgentWallet = ({userInfo}) => {
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' };
      const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

      setCurrentDate(now.toLocaleDateString(undefined, dateOptions));
      setCurrentTime(now.toLocaleTimeString(undefined, timeOptions));
    };

    const intervalId = setInterval(updateDateTime, 1000);
    return () => clearInterval(intervalId);

  }, []);
  return (
    <div>
    <div className="flex flex-col justify-center mt-4 items-center">
  <h1>{currentDate}</h1>
  <div className="relative mt-4	">
    <h1>{currentTime}</h1>
  </div>
  </div>

    <div className="flex justify-center items-center flex-row gap-4 md:gap-6 mt-8">
      <div className="relative">
        <div className="flex p-6 w-[170px] h-auto items-center justify-center rounded-xl bg-white">
          <div className='flex flex-col items-center justify-center'>
           <div className='w-10 h-10 mb-3 bg-green-700 rounded-full flex items-center justify-center'>
              <MdOutlineCurrencyRupee className="w-7 h-7 text-white" />
           </div>
             <p className="text-green-700 text-center mb-3 font-bold text-[19px]">
                {userInfo?.balance}
             </p>
          </div>
        </div>
        <div className="absolute bottom-0 right-0">
          <div className="bg-[#4942ce] flex justify-center items-center px-7 w-[170px] h-[25px] rounded-md">
            <h1 className="text-white">Agnet Wallet</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-col justify-center mt-4 items-center">
  <h1 className='text-[14px]'>1 month 4 days Old</h1>
</div>
<div className="flex justify-center">
      <p className="w-72 bg-[#0d2374] p-2 py-3 shadow-lg mt-12 rounded-xl flex ">
        Join Date : 
        <p className='ml-10'> 
            { formatDateString(userInfo.createdAt || '') }
        </p>
      </p>
    </div>
    <div className="flex justify-center">
      <p className="w-72 bg-[#0d2374] p-2 py-3 shadow-lg mt-5 rounded-xl flex ">
      Agent Id :
      <p className='ml-12'> 
            {userInfo?.id?.substring(5)}
        </p>
      </p>
    </div>

    <div className="flex items-center justify-center gap-7 mt-28">
    <Link
        to={'/'}
        className="bg-[#4942ce] flex items-center justify-center h-[50px] rounded-full text-white w-[250px] text-[18px] px-3 py-1"
      >
        Home
      </Link>
    </div>
  </div>
  )
}

const AgentWalletWrap = withUserInfo(AgentWallet)

export { AgentWalletWrap }
