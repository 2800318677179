import React from "react";
import { Close } from "../../assets/auth";

function UpiModal({ onClose }) {
    const handleClose = () => {
        onClose(false);
      };
  return (
    <div className="w-screen h-screen bg-black bg-opacity-30 px-10 top-0 fixed flex justify-center items-center">
    <div className="bg-yellow-200 p-10 w-[280px] h-[280px] rounded-md shadow-md relative">
        <div
            onClick={handleClose}
            className="absolute top-0 right-0 m-4 p-2 mt-7 rounded-full bg-yellow-200 hover:bg-yellow-600 focus:outline-none"
        >
           <Close/>
        </div>
        <h1 className="font-bold text-black items-center">MY UPI ID</h1>
        <div className="flex justify-between rounded-xl mt-2 bg-white w-[194px] h-[180px]">
          <p className="text-black mt-5 ml-2 text-left">rudendru99@ybl</p> 
          {/* <p className=" mt-5 ml-2  text-black ">
            Note: for changing UPI Id, please contact with admin
          </p> */}
        </div>
      </div>
    </div>
  );
}

export { UpiModal };
