import React from "react";
import { Stack } from "../stack";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { formatDate, formatTime } from "../../utils";
import withTransactionInfo from "../../hoc/transaction";

const Transaction = ({ transactions }) => {
  const data = transactions?.data || []
   return (
    <div className="w-[550px] h-full py-10  px-5 bg-transparent sha shadow-2xl rounded-3xl mt-10">
      <div className="mt-10">
        <h1 className="md:text-[33px] font-bold my-10">Latest Transaction</h1>
        <div className="flex flex-col gap-5">
        {Array.isArray(data) && data.length > 0 ? (
            [...data].reverse()?.slice(0, 10)?.map((item, i) => (
              <Stack
                Icon={MdOutlineCurrencyRupee}
                key={i}
                date={formatDate(item?.createdAt)}
                time={formatTime(item?.createdAt)}
                currency={`₹${item?.amount}`}
              />
            ))
          ) : (
            <p>{"No transactions found or loading...."}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const Transactions = withTransactionInfo(Transaction)

export { Transactions };
