/* eslint-disable no-underscore-dangle */
import axios from "axios";
import { setCredentials } from "../store/auth/authSlice";



const getCookie = (name) => {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length, c.length);
    }
    return null;
};


let store;

let BASE_URL = 'https://dev.acewin.in/api/v1';

if (window.location.host === 'agent.randomplay.store')
    BASE_URL = 'https://dev.randomplay.store/api/v1';

if (process.env.NODE_ENV === 'development')
    BASE_URL = 'http://127.0.0.1:8080/api/v1';


// const BASE_URL = "https://dev.randomplay.store/api/v1/";
// const BASE_URL = "https://dev.acewin.in/api/v1";
// Recommended approach to avoid circular import dependency error
export const injectStore = (_store) => {
  store = _store;
};




export const apiErrorResponse = (error) => {
  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
};

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    // const { accessToken } = store.getState().auth; //Enable when login api integrated

      config.headers = {
        Authorization: getCookie('authorization'),
        Accept: "application/json",
      };
    return config;
  },
  (err) => Promise.reject(err)
);

let calledOnce = false;

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response !== null) {
      if (error.response.status === 403 && !originalRequest._retry) {
        if (!calledOnce) {
          calledOnce = true;
        }
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
