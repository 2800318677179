import React from "react";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-center">
        <Link to={'/all-transaction'} className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-12 rounded-xl flex justify-center">
          All Transactions
        </Link>
      </div>
      <div className="flex justify-center">
        <Link to={'/recharge-details'} className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-5 rounded-xl flex justify-center">
          Recharge Deatils
        </Link>
      </div>
      <div className="flex justify-center">
        <Link to={"/salary-wallet"} className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-5 rounded-xl flex justify-center">
          Salary Wallet
        </Link>
      </div>
      <div className="flex justify-center">
        <Link to={"/agent-wallet"} className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-5 rounded-xl flex justify-center">
          Agent Wallet
        </Link>
      </div>
      <div className="flex justify-center">
        <Link to={'/bank-details'} className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-5 rounded-xl flex justify-center">
          Bank Details
        </Link>
      </div>
      <div className="flex justify-center">
        <Link to={'/security-deposit'} className="w-80 bg-[#0d2374] p-2 py-3 shadow-lg mt-5 rounded-xl flex justify-center">
          Security
        </Link>
      </div>
      <div className="flex justify-center">
        <button className="w-48 bg-[#4942CE] my-24 p-3 py-3 shadow-lg rounded-full flex justify-center">
          Logout
        </button>
      </div>
    </div>
  );
};

export { Menu };
