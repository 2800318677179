import HomeNav from "./HomeNav";
import GlobalNav from "./GlobalNav";
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
 const currentPath = location.pathname;
  // Check if the current route is the home route '/'
  const isHomeRoute = currentPath === "/";

  return (
    <header>
      <nav>{isHomeRoute ? <HomeNav /> : <GlobalNav currentPath={currentPath}/>}</nav>
    </header>
  );
};

export { Navbar };
