import React from "react";
import { SignIcon } from "../currencySign";

const Stack = ({ Icon, date, time, currency }) => {
  const IconComponent = Icon;
  return (
    <div className={`px-5 py-2 rounded-2xl w-full bg-[#0d2374]`}>
      <div className="flex items-center md:gap-20 gap-5">
        <SignIcon
          icon={<IconComponent className="text-white w-7 h-7" />}
          bgColor={"bg-[#A92D4E]"}
        />
        <div className="text-[#818CB8] font-medium">
          <p>{date}</p>
          <p>{time}</p>
        </div>
        <p className="text-[#818CB8] font-medium md:ml-14">{currency}</p>
      </div>
    </div>
  );
};

export { Stack };
