import toast from 'react-hot-toast';

export const successToast = (message) => {
  toast.success(message, {
    style: {
      background: '#333',
      color: '#fff',
    },
  });
};

export const errorToast = (message) => {
  toast.error(message, {
    style: {
      background: '#333',
      color: '#fff',
    },
  });
};

export const formatDate = (createdAt) => {
  const dateObject = new Date(createdAt);
  
  // Format the date
  const day = dateObject.getDate();
  const month = dateObject.toLocaleString('default', { month: 'short' });
  const formattedDate = `${day}${getOrdinalSuffix(day)} ${month}`;

  return formattedDate;
};

export const formatTime = (createdAt) => {
  const dateObject = new Date(createdAt);
  
  // Format the time
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();
  const amPm = hours >= 12 ? 'PM' : 'AM';
  const formattedTime = `${formatTimeUnit(hours)}:${formatTimeUnit(minutes)}:${formatTimeUnit(seconds)} ${amPm}`;

  return formattedTime;
};

const getOrdinalSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const formatTimeUnit = (unit) => {
  return unit < 10 ? `0${unit}` : unit;
};

export const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const formatDateString = (inputDateString) => {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const date = new Date(inputDateString);
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  console.log(`${day}:${month} ${year}`)
  return `${day}:${month} ${year}`;
}