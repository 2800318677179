import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from '../../utils/axiosConfig';

const PLANS_ENDPOINT = 'agent/bank'

export const getBanks = createAsyncThunk('get-bank', async (extra, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.get(PLANS_ENDPOINT);
        return await response.data;
    } catch (error) {
        return rejectWithValue({
            error: error.response.data ? error.response.data.message : error.message,
        });
    }
});