import React, { useState } from "react";
import { Avatar } from "../../assets/auth";
import { BsQrCodeScan } from "react-icons/bs";
import withUserInfo from "../../hoc/userInfo";
import { HiPhoneIncoming } from "react-icons/hi";
import { QRModal } from "../../components/modals/QrPopup";
import { UpiModal } from "../../components/modals/UpiModal";
import { Termsconditionpopup } from "../../components/modals/TermsConditionPopup";

const AgentDetails = ({userInfo}) => {
  const [modalState, setModalState] = useState({})

  const showModals = (text) => {
    setModalState(prevState => ({
      ...prevState,
      [text]: true
    }));
  };

  const isQrcodeModalVisible = modalState?.qrcode;
  const isUpiidModalVisible = modalState?.upiid;
  const isTermConditionModalVisible = modalState?.termscondition;

  return (
    <section className="my-10 w-full">
      <div className="flex flex-col items-center justify-center">
        <div className="w-40 h-40 rounded-full flex items-center justify-center">
          <img src={Avatar} alt="user-profile" />
        </div>
        <div className="my-7">
          <h1 className="text-[#6885f3] text-[25px]">{userInfo?.name}</h1>
        </div>
        <div>
          <div className="flex">
            <div className="flex items-center justify-center w-14 h-14 bg-white rounded-full">
              <HiPhoneIncoming className="text-white h-10 w-10 bg-[#1094a1] rounded-full" />
            </div>

            <div className="w-64 mr-4 my-2 bg-[#1094a1] flex items-center justify-center rounded-full">
              <p className="text-white">{userInfo?.phone}</p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex mt-4 cursor-pointer" onClick={() => showModals('qrcode')}>
            <div className="w-64 ml-14 my-2  bg-[#1094a1] flex items-center justify-center rounded-full">
              <p className="text-white">My QR Code</p>
            </div>
            <div className="flex items-center justify-center w-14 h-14 bg-white rounded-full">
              <BsQrCodeScan  className="text-white h-10 w-10  bg-black rounded-full" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex mt-4 cursor-pointer" onClick={() => showModals('upiid')}>
          <div className="flex items-center justify-center w-14 h-14 bg-white rounded-full">
              <HiPhoneIncoming className="text-white h-10 w-10 bg-[#1094a1] rounded-full" />
            </div>

            <div className="w-64 mr-4 my-2 bg-[#1094a1] flex items-center justify-center rounded-full">
              <p className="text-white">All UPI ID</p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex mt-4">
            <div className="w-64 ml-14 my-2 bg-[#1094a1] flex items-center justify-center rounded-full">
              <p className="text-white">Total Plan</p>
            </div>
            <div className="flex items-center justify-center w-14 h-14 bg-white rounded-full">
              <HiPhoneIncoming className="text-white h-10 w-10 bg-[#1094a1] rounded-full" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex mt-4">
          <div className="flex items-center justify-center w-14 h-14 bg-white rounded-full">
              <HiPhoneIncoming className="text-white h-10 w-10 bg-[#1094a1] rounded-full" />
            </div>

            <div className="w-64  mr-4 my-2 bg-[#1094a1] flex items-center justify-center rounded-full">
              <p className="text-white">Contact Admin</p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex mt-4 cursor-pointer" onClick={() => showModals('termscondition')}>
            <div className="w-64 ml-14 my-2 bg-[#1094a1] flex items-center justify-center rounded-full">
              <p className="text-white">Terms & Cond.</p>
            </div>
            <div className="flex items-center justify-center w-14 h-14 bg-white rounded-full">
              <HiPhoneIncoming className="text-white h-10 w-10 bg-[#1094a1] rounded-full" />
            </div>
          </div>
        </div>
        {isQrcodeModalVisible && <QRModal onClose={() => setModalState(prevState => ({ ...prevState, qrcode: false }))} />}
        {isUpiidModalVisible && <UpiModal onClose={() => setModalState(prevState => ({ ...prevState, upiid: false }))} />}
        {isTermConditionModalVisible && <Termsconditionpopup onClose={() => setModalState(prevState => ({ ...prevState, termscondition: false }))} />}
        <button className="bg-[#4942ce] mb-2 p-2 py-2 w-48 shadow-lg mt-8 rounded-full flex   justify-center ">
          Logout
        </button>
      </div>
    </section>
  );
};

const userAgentDetails = withUserInfo(AgentDetails)

export { userAgentDetails };
