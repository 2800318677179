import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { menuTitle } from "../../constants";
import { useNavigate } from "react-router-dom";

const GlobalNav = ({ currentPath }) => {
  const headLine = menuTitle[currentPath.substr(1)];
  const navigate = useNavigate();
  console.log({ headLine, currentPath });
  // This will go back to the previous route
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex items-center justify-between">
      <button
        className="bg-white text-black p-3 rounded-full"
        onClick={handleGoBack}
      >
        <FaArrowLeft className="w-5 h-5" />
      </button>
      <div>
        <span className="font-bold text-[18px] text-[#8f8e8e]">{headLine}</span>
      </div>
      <div></div>
    </div>
  );
};

export default GlobalNav;
