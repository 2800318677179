import { createSlice } from "@reduxjs/toolkit";
import { getBanks } from "./bankActions";

const initialState = {
  loading: false,
  banks: null,
  error: null,
  success: false,
};

const BankSlice = createSlice({
  name: "banks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBanks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBanks.fulfilled, (state, action) => {
        state.loading = false;
        state.banks = action.payload;
      })
      .addCase(getBanks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default BankSlice.reducer