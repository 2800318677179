import { Link } from "react-router-dom";
import { Button } from "../../components/button";
import { Transactions } from "../../components/transaction";
import withUserInfo from "../../hoc/userInfo";
import { getCookie } from "../../utils";


const Home = ({userInfo}) => {
  const authToken = getCookie("authorization")
  console.log({authToken})
  return (
    <section className="my-10">
      <div>
        <h1 className="text-[#6885f3] md:text-[28px] font-medium tracking-[2px]">
          Balance
        </h1>
        <div className="mt-7">
          <h1 className="md:text-5xl font-extrabold">₹{userInfo?.balance}</h1>
          <div className="flex items-center justify-center gap-7 mt-16">
            <Link to={'/buy-plan'}
              className="bg-white text-black w-32 text-[18px] px-3 py-1 rounded-full font-bold"
            >
              Buy Plan
            </Link>
            <Button
              text="Home"
              style="bg-white text-black w-32 text-[18px] px-3 py-1"
            />
          </div>
        </div>

        <div className="flex items-center justify-center">
          <Transactions/>
        </div>
      </div>
    </section>
  );
};

const HomePage = withUserInfo(Home) 

export { HomePage };
