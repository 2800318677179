import React from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { Link } from "react-router-dom";
import { Avatar } from "../../assets/auth";
import withUserInfo from "../../hoc/userInfo";

const HomeNav = ({userInfo}) => {
  return (
    <div className="flex items-center justify-between">
      <Link to="/menu" className="bg-[#221697] text-white p-3 rounded-full">
        <HiMenuAlt1 className="w-8 h-8" />
      </Link>
      <div className="min-w-[150px] rounded-3xl text-white bg-[#4b32b4] flex items-center gap-7 px-3 py-2">
        <span className={`w-2 h-2 ml-2 rounded-full ${userInfo?.active ? 'bg-[#0cdc73]': 'bg-yellow-300'}`}></span>
        <span className="font-bold text-[18px]">{userInfo?.active ? 'Active' : 'Inactive'}</span>
      </div>
      <Link to="/agent-details" className="w-14 h-14 rounded-full">
        <img src={Avatar} alt="user-avatar" className="aspect-auto" />
      </Link>
    </div>
  );
};

export default withUserInfo(HomeNav);
