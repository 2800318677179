import React, { useEffect } from "react";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRechargeWallet } from "../../store/recharge/rechargeActions";

const RechargeDetails = () => {
  const { recharge : data = {} } = useSelector(state => state.recharge)
  const dispatch = useDispatch()

  useEffect(()=> {
    dispatch(getRechargeWallet())
  },[])

  return (
    <div>
    <div className="flex justify-center items-center flex-row gap-4 md:gap-6 mt-8">
      <div className="relative">
        <div className="flex p-6 w-[170px] h-auto items-center justify-center rounded-xl bg-white">
        <div className='flex flex-col items-center justify-center'>
           <div className='w-10 h-10 mb-3 bg-green-700 rounded-full flex items-center justify-center'>
              <MdOutlineCurrencyRupee className="w-7 h-7 text-white" />
           </div>
             <p className="text-green-700 text-center mb-3 font-bold text-[19px]">
                {data?.data.total}
             </p>
          </div>
        </div>
        <div className="absolute bottom-0 right-0">
          <div className="bg-[#4942ce] flex px-7 w-[170px] h-[25px] rounded-md">
            <h1 className="text-white text-center flex justify-center items-center">
              Total Recharge
            </h1>
          </div>
        </div>
      </div>
    </div>
  
    <div className=" mt-10 flex justify-center items-center">
      <div className="flex justify-center items-center  flex-row gap-4 md:gap-6">
        <div className="relative">
          <div className="flex p-6 w-[120px] h-[70px] items-center justify-center rounded-xl bg-white">
          <div className='w-5 h-5 mb-3 bg-green-700 rounded-full flex items-center justify-center'>
              <MdOutlineCurrencyRupee className="w-3 h-3 text-white" />
           </div>
            <p className="text-black mt-[-13px] ml-1 font-bold text-[19px]">
              {data?.data?.today}
            </p>
          </div>
          <div className="absolute bottom-0 right-0">
            <div className="bg-[#4942ce] flex px-7 w-[120px] h-[25px] rounded-md">
              <h1 className="text-white text-center flex justify-center items-center">
                Today
              </h1>
            </div>
          </div>
        </div>
  
        <div className="relative">
          <div className="flex p-6 w-[120px] h-[70px] items-center justify-center rounded-xl bg-white">
          <div className='w-5 h-5 mb-3 bg-green-700 rounded-full flex items-center justify-center'>
              <MdOutlineCurrencyRupee className="w-3 h-3 text-white" />
           </div>
            <p className="text-black  mt-[-13px] ml-1 font-bold text-[19px]">
              {data?.data?.yesterday}
            </p>
          </div>
          <div className="absolute bottom-0 right-0">
            <div className="bg-[#4942ce] flex px-7 w-[120px] h-[25px] rounded-md">
              <h1 className="text-white text-center flex justify-center  items-center">
                Yesterday
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex items-center justify-center gap-7 mt-28">
    <Link
        to={'/'}
        className="bg-[#4942ce] flex items-center justify-center h-[50px] rounded-full text-white w-[250px] text-[18px] px-3 py-1"
      >
        Back
      </Link>
          </div>
  </div>
  
  );
};

export { RechargeDetails };

