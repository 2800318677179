import React from 'react';
import { Close } from '../../assets/auth';

const Termsconditionpopup = ({ onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="w-screen h-screen bg-black bg-opacity-30 px-10 top-0 fixed flex justify-center items-center">
      <div className="bg-yellow-200 p-10 w-[480px] h-[580px] rounded-md shadow-md relative">
        <div
          onClick={handleClose}
          className="absolute top-0 right-0 m-4 p-2 mt-7 rounded-full bg-yellow-200 hover:bg-yellow-600 focus:outline-none"
        >
         <Close/>
        </div>
        <h1 className="font-bold text-black mb-4 text-[28px]">Terms & Conditions </h1>
        <div className="text-gray-800 flex float-left">
          <ol className="list-decimal pl-6">
            <li className="mb-2">
              <p>Commision : flat 5% </p>
            </li>
            <li className="mb-2">Only balance in salary form will be transferred to the agent.</li>
            <li className="mb-2">We do not share any chargeback.</li>
            <li className="mb-2">Agent can also opt for USDT.</li>
            <li className="mb-2">Only admin decides the payment form INR/USDT.</li>
            <li className="mb-2">IDs are provided only on the basis of refer.</li>
            <li>Admin decision is final in all types of transactions.</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export { Termsconditionpopup };
