import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from '../../utils/axiosConfig';

const USER_ENDPOINT = 'agent/profile'

export const getUser = createAsyncThunk('get-user', async (extra, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.get(USER_ENDPOINT);
        return await response.data;
    } catch (error) {
        return rejectWithValue({
            error: error.response.data ? error.response.data.message : error.message,
        });
    }
});