import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from '../../utils/axiosConfig';

const RECHARGE_ENDPOINT = 'agent/salary/report'

export const getRechargeWallet = createAsyncThunk('get-recgarge-details', async (extra, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.get(RECHARGE_ENDPOINT);
        return await response.data;
    } catch (error) {
        return rejectWithValue({
            error: error.response.data ? error.response.data.message : error.message,
        });
    }
});