import React from 'react';
import { Stack } from '../../components/stack';
import { MdOutlineCurrencyRupee } from 'react-icons/md';
import withTransactionInfo from '../../hoc/transaction';
import { formatDate, formatTime } from '../../utils';

const alltransaction = ({transactions}) => {
  const data = transactions?.data || []

  return (
    <div className="w-90 min-h-[650px] py-2 px-5 bg-transparent shadow-2xl rounded-3xl mt-10 overflow-y-auto">
      <div className="mt-10">
        <div className="flex flex-col gap-5">
          {Array.isArray(data) && data.length > 0 ? (
            data.map((item, i) => (
              <Stack
                Icon={MdOutlineCurrencyRupee}
                key={i}
                date={formatDate(item?.createdAt)}
                time={formatTime(item?.createdAt)}
                currency={`₹${item?.amount}`}
              />
            ))
          ) : (
            <p>{"No transactions found or loading...."}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const UserTransactions = withTransactionInfo(alltransaction)

export { UserTransactions };
