import { useEffect, useRef, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { errorToast, getCookie } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/auth/authActions';

export const Login = () => {
  const { user } = useSelector(state => state.auth)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log({user})

  let phone = useRef('');
  let password = useRef('');

//   useEffect(() => {
// 	const authToken = getCookie("authorization")
//     if (authToken) {
//       navigate('/', { successLogin: true });
//     }
//   }, [navigate]);

const navigationLink = () => {
	navigate('/menu', { successLogin: true });
}

  const signinUser = async (e) => {
    e.preventDefault();
	dispatch(loginUser({
		phone: phone.current?.value,
        password: password.current.value,
		navigationLink,
		errorToast
	}))
	// const authToken = getCookie("authorization")
	// if(authToken) {
    //     dispatch(loginUser({
	// 		phone: phone.current?.value,
	// 		password: password.current.value,
	// 	   }))
	// 		 .unwrap()
	// 		 .catch((errorData) => {
	// 		   errorToast(errorData.error);
	// 		 });
	// } else {
	// 	navigate('/', { successLogin: true });
	// }
  };

  return (
		<section>
			<Toaster />
			<form onSubmit={signinUser}>
			  <div className='bg-white h-[250px] relative rounded-b-2xl rounded-bl-2xl mb-[-10px] -z-10'>
				  <img src={'https://assets.acewin.in/app%2Flogo.png'} alt='logo' className='absolute bottom-16'/>
			  </div>
			  <div className='h-screen rounded-2xl p-20 login-form flex items-center flex-col'>
				<div className='text-center'>
				<h1 className='text-[35px] font-bold text-white'>Agent Login</h1>
				<p className='text-white mt-3 font-normal'>Signin to continue</p>
				</div>
				<div className='my-10 min-w-[300px]'>
					 <div className='mb-4 w-full'>
						 <h3 className='mb-5 text-white'>
							PHONE
						 </h3>
						 <input type="text" ref={phone} className='appearance-none w-full rounded-full text-white placeholder:text-white bg-[#404587] px-3 py-2'/>
					 </div>
					 <div className='w-full'>
						 <h3 className='mb-5 text-white'>
							PASSWORD
						 </h3>
						 <input type="password" ref={password} className='appearance-none w-full rounded-full text-white placeholder:text-white bg-[#404587] px-3 py-2'/>
					 </div>
					 <div className='my-5'>
					 <Button text={'Login'} style={'bg-[#4942CE] w-full  px-3 py-2 text-white'}/>
					 <div className='flex items-center justify-between mt-3 text-white'>
						<p className='font-normal'>Change Password?</p>
						<p className='font-normal'>Get Agent Id!!</p>
					 </div>
					 </div>
				</div>
			  </div>
			</form>
		</section>
  );
};
