import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Navbar } from '../navbar';
import Styles from './private.module.scss';
const PrivateRoute = ({ component: Component, ...props }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className={Styles.contentContainer}>
      <Navbar />
      {!user ? (
        <Navigate
          to={{ pathname: '/login', state: { from: props.location } }}
          replace
        />
      ) : (
        <Component {...props} />
      )}
    </div>
  );
};

export { PrivateRoute };
