import EmailIcon from './emailIcon.svg';
import PasswordIcon from './passwordIcon.svg';
import UserIcon from './userIcon.svg';
import Avatar from './user-awatar.png'
import QRBar from './qrscan.png'
import SecurityImg from './agent-security.webp'
const Close = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  )
}

export {
  EmailIcon,
  PasswordIcon,
  UserIcon,
  Avatar,
  Close,
  QRBar,
  SecurityImg
};
