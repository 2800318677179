import React from 'react'
import { Link } from 'react-router-dom'
import { SecurityImg } from '../../assets/auth'
import withUserInfo from '../../hoc/userInfo'

const SecurityDeposit = ({userInfo}) => {
  return (
  <div>
    <div className="flex justify-center">
     <p className="w-72 bg-[#0d2374] p-2 py-3 shadow-lg mt-16 rounded-xl flex ">
      Amount:
      <p className='ml-12'> 
          {userInfo?.security}  INR
      </p>
    </p>
    </div>
    <div className='mt-3'>
       <img src={SecurityImg} alt="security" />
    </div>
  <div className="flex items-center justify-center gap-7 mt-20">
      <Link
        to={'/'}
        className="bg-[#4942ce] flex items-center justify-center h-[50px] rounded-full text-white w-[250px] text-[18px] px-3 py-1"
      >
        Home
      </Link>
    </div>
  </div>
  )
}

const SecurityDepositWrapper = withUserInfo(SecurityDeposit)

export {SecurityDepositWrapper}