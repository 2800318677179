import React from "react";

const SignIcon = ({ icon, bgColor }) => {
  return (
    <div
      className={`rounded-full ${bgColor} w-10 h-10 flex items-center justify-center`}
    >
      {icon}
    </div>
  );
};

export { SignIcon };
