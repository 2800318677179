import Styles from './app.module.scss';
import { Menu } from '../../pages/menus';
import { PublicRoute} from '../publicRoute'
import { Plans } from '../../pages/buyplan';
import { HomePage } from '../../pages/home';
import { PrivateRoute } from '../privateRoute';
import { Route, Routes } from 'react-router-dom';
import { Login } from '../../pages/login/login';
import { Bankdeatils } from '../../pages/bankdetail';
import { userAgentDetails } from '../../pages/details';
import { UserTransactions } from '../../pages/alltransactions';
import { SalaryWallet } from '../../pages/salarywallet';
import { AgentWalletWrap } from '../../pages/agentwallet';
import { SecurityDepositWrapper } from '../../pages/securitydeposit';
import { RechargeDetails } from '../../pages/rechargedetails';
function App() {
  return (
		<div>
			<Layout>
				<Routes>
					<Route path="/" element={<PrivateRoute component={HomePage} />} />
					<Route path="/agent-details" element={<PrivateRoute component={userAgentDetails} />} />
					<Route path="/menu" element={<PrivateRoute component={Menu} />} />
					<Route path="/all-transaction" element={<PrivateRoute component={UserTransactions} />} />
					<Route path="/buy-plan" element={<PrivateRoute component={Plans} />} />
					<Route path="/bank-details" element={<PrivateRoute component={Bankdeatils} />} />
					<Route path="/salary-wallet" element={<PrivateRoute component={SalaryWallet} />} />
					<Route path="/recharge-details" element={<PrivateRoute component={RechargeDetails} />} />
					<Route path="/agent-wallet" element={<PrivateRoute component={AgentWalletWrap}/>} />
					<Route path="/security-deposit" element={<PrivateRoute component={SecurityDepositWrapper}/>} />
					{/* { rest of the pages routes here } */}
					 <Route path='/login' element={<PublicRoute restricted={true} component={Login} />} />
					{/*<Route path='/register' element={<PublicRoute restricted={true} component={Register} />} /> */}
				</Routes>
			</Layout>
		</div>
  );
}

const Layout = ({ children }) => (
	<div className={Styles.mainContainer}>
		<>
			{children}
		</>
	</div>
);

export { App };
